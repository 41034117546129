<template>
  <ul class="menu-nav">
    <router-link to="/" v-slot="{ href, navigate, isActive, isExactActive }">
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-architecture-and-city"></i>
          <span class="menu-text">Trang chủ</span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/admin"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon-interface-3"></i>
          <span class="menu-text">Quản lý</span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/user-profile"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fa fa-user"></i>
          <span class="menu-text">a</span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/listMeeting"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fa fa-list"></i>
          <span class="menu-text">Danh Sách Cuộc Họp</span>
        </a>
      </li>
    </router-link>

    <li class="menu-section">
      <h4 class="menu-text">Phòng họp</h4>
      <i class="menu-icon flaticon-interface-3"></i>
    </li>

    <router-link
      :to="`/${room.id}/${room.roomLink}`"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-for="room in roomList"
      :key="room.id"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span class="menu-text">{{ room.roomName }}</span>
        </a>
      </li>
    </router-link>
  </ul>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { removeAscent } from "@/core/helpers/stringHandling";

export default {
  name: "KTMenu",
  data() {
    return {
      roomList: [],
    };
  },
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },
  },
  async created() {
    const { data } = await ApiService.get("/room/getList");
    const roomList = data.data.map((item) => {
      item.roomLink = removeAscent(item.roomName.replace(/ /g, ""));
      return item;
    });

    this.roomList = roomList;
  },
};
</script>
