<template>
  <div class="topbar-item">
    <div
      class="quick-user-text btn w-auto d-flex align-items-center px-2"
      id="kt_quick_user_toggle"
    >
      <span class="font-weight-bold font-size-base d-none d-md-inline mr-1">
        Hi,
      </span>
      <span class="font-weight-bolder font-size-base d-none d-md-inline mr-3">
        {{ userInfo && userInfo.username }}
      </span>
    </div>

    <div
      id="kt_quick_user"
      ref="kt_quick_user"
      class="offcanvas offcanvas-right p-10"
    >
      <!--begin::Header-->
      <div
        class="offcanvas-header d-flex align-items-center justify-content-between pb-5"
      ></div>
      <!--end::Header-->

      <!--begin::Content-->
      <perfect-scrollbar
        v-if="userInfo != null"
        class="offcanvas-content pr-5 mr-n5 scroll"
        style="max-height: 90vh; position: relative"
      >
        <!--begin::Header-->
        <div class="d-flex align-items-center mt-5">
          <div class="w-100 d-flex flex-column">
            <p class="font-weight-bold font-size-h6 text-dark-75 mb-0">
              <b>Phòng ban:</b> {{ userInfo.userDepartment }}
            </p>
            <p class="font-weight-bold font-size-h6 text-dark-75">
              <b>Email:</b> {{ userInfo.email }}
              {{ userInfo.role === "admin" ? `(${userInfo.role})` : "" }}
            </p>
            <div class="navi mt-2"></div>
            <div class="d-flex">
              <!-- <FormEditProfileMySelf v-bind:userInfo="userInfo" /> -->
              <button
                class="btn btn-light-primary btn-bold w-100"
                @click="onLogout"
              >
                Đăng xuất
              </button>
            </div>
          </div>
        </div>
        <!--end::Header-->
        <!--begin::Nav-->
        <div class="navi navi-spacer-x-0 p-0"></div>
        <!--end::Nav-->
      </perfect-scrollbar>
      <!--end::Content-->
    </div>
  </div>
</template>

<style lang="scss" scoped>
#kt_quick_user {
  overflow: hidden;
}
</style>

<script>
import { LOGOUT } from "@/core/services/store/auth.module";
import KTLayoutQuickUser from "@/assets/js/layout/extended/quick-user.js";
import ApiService from "@/core/services/api.service";
// import { splitEmail } from "@/core/helpers/stringHandling";
import { getToken } from "@/core/services/jwt.service";
import { mapState } from "vuex";

export default {
  name: "KTQuickUser",
  data() {
    return {
      userInfo: null,
    };
  },
  async mounted() {
    // Init Quick User Panel
    KTLayoutQuickUser.init(this.$refs["kt_quick_user"]);

    if (getToken()) {
      const { data } = await ApiService.get("/verify");
      const { email, role, departmentId, id, fullname } = data.data;
      // const username = splitEmail(email)[1];

      const userDepartment = this.departmentList.find(
        (item) => item.id === departmentId
      );

      const userInfo = {
        id,
        email,
        username:
          fullname.length > 12 ? fullname.slice(0, 12) + "..." : fullname,
        role,
        departmentId,
        userDepartment: userDepartment ? userDepartment.departmentName : "",
      };

      this.userInfo = userInfo;
    }
  },
  methods: {
    onLogout() {
      this.$store
        .dispatch(LOGOUT)
        .then(() => this.$router.push({ name: "login" }));
    },
  },
  computed: {
    ...mapState({
      departmentList: (state) => {
        return state.store.departmentList;
      },
    }),
  },
};
</script>

<style scoped>
.quick-user-text {
  padding: 0.3rem 0.5rem;
  color: white;
}
.quick-user-text:hover {
  background-color: white;
  color: #bd1e2d;
}
</style>