<template>
  <div
    v-if="isLoading"
    class="loading-screen"
    :style="`background: ${background}; z-index: ${zIndex};`"
  >
    <div
      class="w-100 h-100 d-flex flex-column justify-content-center align-items-center"
    >
      <img src="media/logos/ddv2.png" alt="..." class="mb-10" />
      <div class="spinner spinner-danger spinner-lg"></div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "loading-screen",
  computed: {
    ...mapGetters(["isLoading", "loaderStyle"]),
    background() {
      if (!this.loaderStyle) return "rgb(0 0 0 / 50%)";
      switch (this.loaderStyle.background) {
        case "black transparent":
          return "rgb(0 0 0 / 50%)";
        case "white":
          return "white";
        default:
          return "rgb(0 0 0 / 50%)";
      }
    },
    zIndex() {
      if (!this.loaderStyle) return 1020;
      const { zIndex } = this.loaderStyle;
      switch (typeof zIndex) {
        case "number":
          return zIndex;
        default:
          return 1020;
      }
    },
  },
  // watch: {
  //   isLoading: {
  //     immediate: true,
  //     async handler(isLoading) {
  //       console.log("isLoading: ", isLoading);
  //     },
  //   },
  // },
};
</script>

<style scoped>
.loading-screen {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}
</style>