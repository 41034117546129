<template>
  <!-- begin:: Footer -->
  <div></div>
  <!-- end:: Footer -->
</template>

<script>
export default {
  name: "KTFooter",
  computed: {},
};
</script>
