<template>
  <ul class="menu-nav">
    <!-- <router-link to="/" v-slot="{ href, navigate }">
      <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item">
        <a
          :href="href"
          class="menu-link"
          @click="navigate"
          style="padding-right: 20px; background-color: #bd1e2d"
        > -->
    <img
      src="media/logos/logo-ddv.webp"
      alt="..."
      style="padding-right: 40px"
    />
    <!-- </a>
      </li>
    </router-link> -->

    <!-- <router-link to="/" v-slot="{ href, navigate, isExactActive }">
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[isExactActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span class="menu-text"> Trang chủ </span>
        </a>
      </li>
    </router-link> -->

    <router-link
      v-if="firstRoom"
      :to="`/${firstRoom.id}/${firstRoom.roomLink}`"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span class="menu-text"> Đặt phòng họp </span>
        </a>
      </li>
    </router-link>

    <router-link
      v-if="firstRoom"
      :to="`/listMeeting`"
      v-slot="{ href, navigate, isActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[isActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span class="menu-text"> Danh sách cuộc họp </span>
        </a>
      </li>
    </router-link>

    <li
      aria-haspopup="true"
      data-menu-toggle="click"
      class="menu-item menu-item-submenu menu-item-open-dropdown"
      v-bind:class="{ 'menu-item-active': hasActiveChildren('/vue-bootstrap') }"
      v-if="role === 'admin'"
    >
      <a href="#" class="menu-link menu-toggle">
        <span class="menu-text"> Quản lý </span>
      </a>
      <div class="menu-submenu menu-submenu-fixed">
        <div class="menu-subnav megamenu" style="width: 1000px">
          <ul class="menu-content">
            <li v-if="role === 'admin'" class="menu-item d-none">
              <h3 class="menu-heading menu-toggle">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text"> Người dùng </span>
                <i class="menu-arrow"></i>
              </h3>
              <ul class="menu-inner">
                <router-link
                  to="/listOfUser"
                  v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                    aria-haspopup="true"
                    class="menu-item"
                    :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ]"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <span class="menu-text"> Danh sách người dùng </span>
                    </a>
                  </li>
                </router-link>
              </ul>
            </li>

            <li class="menu-item" v-if="role === 'admin'">
              <h3 class="menu-heading menu-toggle">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text"> Phòng họp </span>
                <i class="menu-arrow"></i>
              </h3>
              <ul class="menu-inner">
                <router-link
                  to="/listOfRoom"
                  v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                    aria-haspopup="true"
                    class="menu-item"
                    :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ]"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <span class="menu-text"> Danh sách phòng họp </span>
                    </a>
                  </li>
                </router-link>
              </ul>
            </li>

            <li class="menu-item" v-if="role === 'user'">
              <h3 class="menu-heading menu-toggle">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text"> Thông tin cá nhân </span>
                <i class="menu-arrow"></i>
              </h3>
              <ul class="menu-inner">
                <router-link
                  to="/user-profile"
                  v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                    aria-haspopup="true"
                    class="menu-item"
                    :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ]"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <span class="menu-text"> Cập nhật thông tin </span>
                    </a>
                  </li>
                </router-link>
              </ul>
            </li>

            <li class="menu-item">
              <h3 class="menu-heading menu-toggle">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text"> Cuộc họp </span>
                <i class="menu-arrow"></i>
              </h3>
              <ul class="menu-inner">
                <router-link
                  to="/listMeeting"
                  v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                    aria-haspopup="true"
                    class="menu-item"
                    :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ]"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <span class="menu-text"> Danh sách cuộc họp </span>
                    </a>
                  </li>
                </router-link>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { removeAscent } from "@/core/helpers/stringHandling";
import { mapGetters } from "vuex";

export default {
  name: "KTMenu",
  data() {
    return {
      // roomList: [],
      firstRoom: null,
    };
  },
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },
  },
  async created() {
    const { data } = await ApiService.get("/room/getList");
    const roomList = data.data.map((item) => {
      item.roomLink = removeAscent(item.roomName.replace(/ /g, ""));
      return item;
    });

    // this.roomList = roomList;
    this.firstRoom = roomList[0];
  },
  computed: {
    ...mapGetters(["currentUser"]),

    role() {
      return this.currentUser.role;
    },
  },
};
</script>
